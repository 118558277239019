import {PostPermissionRequest} from './PostRequestModels/PermissionsRequests'
import axios from 'axios'
import {IHostPropertyInput, IHostPropertyVendorInput} from '../interfaces/Property'
import {Endpoints} from './Endpoints'
import {
  AcceptStaffInviteRequest,
  HostRegisterRequest,
  LoginRequest,
  RegisterRequest,
  StaffAddRequest,
} from './PostRequestModels'
import {AddressRequest} from './PostRequestModels/AddressRequests'
import {
  ChecklistAddRequest,
  ChecklistEditRequest,
  ChecklistMainUpdateRequest,
  ChecklistQuestionEditRequest,
  ChecklistSectionAddRequest,
  ChecklistSectionEditRequest,
  ChecklistSetQuestionOrderRequest,
  ChecklistSetSectionOrderRequest,
} from './PostRequestModels/ChecklistRequests'
import {CityService, DelCityService, StaffCityService} from './PostRequestModels/CityService'
import {
  AssignStaffJobRequest,
  ExcludedStaffRequest,
  IScheduledJobRequest,
  JobNoteRequest,
  JobReviewRequest,
  JobServiceAddRequest,
  ManualJobRequest,
} from './PostRequestModels/JobRequests'
import {IPostOffDays} from './PostRequestModels/OffDaysRequest'
import {IOfferedPut} from './PostRequestModels/OfferedRequest'
import {PermissionRequest} from './PostRequestModels/Permission'
import {GuestyRequest, HostawayRequest, MyVrRequest} from './PostRequestModels/PropertyFromPMS'
import {ReferenceRequest, ReferenceUpdateRequest} from './PostRequestModels/ReferenceRequests'
import {IBillingInformation} from '../interfaces/Billing'
import * as Sentry from '@sentry/react'
import {IVendorServiceRequest, IServicePrice} from './PostRequestModels/VendorRequestForm'
import {DeclineVirtualInspect} from './PostRequestModels/VirtualInspectionRequest'
import {IStaffAddress} from '../interfaces/Address'
import {ForgotRequest} from './PostRequestModels/UserRequest'
import {IHostEdit} from '../interfaces/Host'

var httpClient = axios.create({
  httpsAgent: {
    rejectUnauthorized: false,
  },
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

var httpClientFormData = axios.create({
  httpsAgent: {
    rejectUnauthorized: false,
  },
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
})
httpClient.defaults.timeout = 500000
httpClientFormData.defaults.timeout = 500000

httpClient.interceptors.response.use(
  (res) => res,
  (err) => {
    try {
      if (err?.response?.data?.statusCode === 500) {
        Sentry.captureException({
          responseData: {...err?.response?.data},
          requestData: JSON.parse(err?.response?.config?.data),
          url: err?.response?.config?.url,
        })
      }
    } catch (interceptorError) {
      //Do not sent it to Sentry
    } finally {
      return Promise.reject(err)
    }
  }
)

httpClientFormData.interceptors.response.use(
  (res) => res,
  (err) => {
    try {
      if (err?.response?.data?.statusCode === 500) {
        Sentry.captureException({
          responseData: {...err?.response?.data},
          requestData: JSON.parse(err?.response?.config?.data),
          url: err?.response?.config?.url,
        })
      }
    } catch (interceptorError) {
      //Do not sent it to Sentry
    } finally {
      return Promise.reject(err)
    }
  }
)

interface IApiCalls {}

class ApiCalls implements IApiCalls {
  private server_link: string
  public token: string
  public logout: () => void
  constructor() {
    this.server_link = 'https://trnfy.com/api/' //Production
    // this.server_link = 'http://localhost:5139/api/' //LOCAL
    this.token = 'not set'
    this.logout = () => {}
  }

  ressetToken = () => {
    const newHttpClient = axios.create({
      httpsAgent: {
        rejectUnauthorized: false,
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    newHttpClient.defaults.timeout = 500000
    httpClient = newHttpClient
  }

  setToken = (token: string) => {
    httpClient.defaults.headers['Authorization'] = 'Bearer ' + token
    httpClientFormData.defaults.headers['Authorization'] = 'Bearer ' + token
    httpClient.interceptors.response.use(
      (res) => res,
      (err) => {
        try {
          if (err?.response?.data?.statusCode === 401) {
            this.logout()
          }
          if (err?.response?.data?.statusCode === 500) {
            Sentry.captureException({
              responseData: {...err?.response?.data},
              requestData: JSON.parse(err?.response?.config?.data),
              url: err?.response?.config?.url,
            })
          }
        } catch (interceptorError) {
          //Do not sent it to Sentry
        } finally {
          return Promise.reject(err)
        }
      }
    )
    httpClientFormData.interceptors.response.use(
      (res) => res,
      (err) => {
        try {
          if (err?.response?.data?.statusCode === 401) {
            this.logout()
          }
          if (err?.response?.data?.statusCode === 500) {
            Sentry.captureException({
              responseData: {...err?.response?.data},
              requestData: JSON.parse(err?.response?.config?.data),
              url: err?.response?.config?.url,
            })
          }
        } catch (interceptorError) {
          //Do not sent it to Sentry
        } finally {
          return Promise.reject(err)
        }
      }
    )
  }

  setLogout = (func: () => void) => {
    this.logout = func
  }

  //Auth
  login = (loginRequest: LoginRequest) => {
    return httpClient.post(this.server_link + Endpoints.Users.Login, loginRequest)
  }

  register = (registerRequest: RegisterRequest) => {
    return httpClient.post(this.server_link + Endpoints.Users.Register, registerRequest)
  }

  verifyUser = (token: string) => {
    return httpClient.post(this.server_link + Endpoints.Users.ConfirmAccount, {token: token})
  }

  getUserDetails = () => {
    return httpClient.get(this.server_link + Endpoints.Users.GetUserDetails)
  }

  getUserDetailsById = (userId: string) => {
    return httpClient.get(this.server_link + Endpoints.Users.GetUserDetailsById + userId)
  }
  getUserDetailsByVendorId = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Users.GetUserDetailsByVendorId + vendorId)
  }

  editUser = (data: {formData: any; userId: string}) => {
    return httpClient.put(this.server_link + Endpoints.Users.EditUser + data.userId, data.formData)
  }
  changePassword = (id: number, data: any) => {
    return httpClient.put(this.server_link + Endpoints.Users.ChangePassword + id, data)
  }
  //Account Types
  getAccountStatusTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Account.GetAccountStatusType)
  }
  //Jobs
  getLiveJobs = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetLiveJobs + '?vendorId=' + vendorId)
  }
  getJobsAvailable = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobsAvailable + vendorId)
  }
  getStaffEmailNotVerified = (vendorId: number | undefined, accountStatusId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Staff.GetStaffsByVendorId +
        vendorId +
        '&accountStatusId=' +
        accountStatusId
    )
  }
  postAssignStaffToJob = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Job.CreateAssignStaffToJob, data)
  }
  scheduleJob = (data: AssignStaffJobRequest) => {
    return httpClient.put(this.server_link + Endpoints.Job.ScheduleJob, data)
  }
  putSchedule = (data: AssignStaffJobRequest) => {
    return httpClient.put(this.server_link + Endpoints.Job.AssignStaffToJob, data)
  }
  putReSchedule = (data: AssignStaffJobRequest) => {
    return httpClient.put(this.server_link + Endpoints.Job.AssignStaffReSchedule, data)
  }
  declineSchedule = (data: any) => {
    return httpClient.put(this.server_link + Endpoints.Job.DeclineJobSchedule, data)
  }
  getJobsByStatusId = (hostId: number, jobStatusId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Job.GetJobsByStaffId + hostId + '&jobStatusTypeId=' + jobStatusId
    )
  }
  getJobsByStatusTypeId = (vendorId: number, jobStatusTypeId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Job.GetJobByStatusTypeId +
        'vendorId=' +
        vendorId +
        '&' +
        'jobStatusTypeId=' +
        jobStatusTypeId
    )
  }

  getJobs = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetAllJobs + vendorId)
  }
  postExcludedStaffToJob = (data: ExcludedStaffRequest) => {
    return httpClient.post(this.server_link + Endpoints.Job.ExcludeStaffToJob, data)
  }
  postAssignPriority = (data: PostPermissionRequest) => {
    return httpClient.post(this.server_link + Endpoints.Job.AssignPriority, data)
  }
  getOffDayEffectedJob = (userId: number, offDay: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Job.GetAllJobs +
        '?userId=' +
        userId +
        '&jobStatusTypeId=2&offDay=' +
        offDay
    )
  }
  getStaffForAssignJob = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetStaffsForAddJob + vendorId)
  }

  //Force-Finish-Request
  postForceFinishRequest = (data: any) => {
    return httpClientFormData.post(this.server_link + Endpoints.Job.PostForceFinishReq, data)
  }

  //Virtual Inspections((
  getHasVirtualInspectionsJobs = (vendorId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Job.GetVirtualInspection + '?vendorId=' + vendorId
    )
  }
  getVirtualInspections = (jobId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.VirtualInspection.GetVirtualInspections + jobId
    )
  }

  getVirtualInspectionsByJobId = (id: any) => {
    return httpClient.get(
      this.server_link + Endpoints.VirtualInspection.GetVirtualInspectionsByJobId + id
    )
  }

  postVirtualInspections = (jobServiceId: number) => {
    return httpClient.post(this.server_link + Endpoints.VirtualInspection.PostVirtualInspections, {
      jobServiceId,
    })
  }
  putApproveVirtualInspections = (jobId: number) => {
    return httpClient.put(
      this.server_link +
        Endpoints.VirtualInspection.PutApproveVirtualInspections +
        jobId +
        '/approve'
    )
  }
  putDeclineVirtualInspections = (data: DeclineVirtualInspect) => {
    return httpClient.put(
      this.server_link + Endpoints.VirtualInspection.PutDeclineVirtualInspections + 'decline',
      data
    )
  }

  //Marketplace
  getMarketplaceRequest = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Marketplace.GetRequests + hostId)
  }

  getMarketplaceOfferDetail = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Marketplace.GetOfferDetail, data)
  }

  sendMarketplaceOffer = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Marketplace.SendOffer, data)
  }

  cancelMarketplaceOffer = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Marketplace.CancelOffer, data)
  }

  getVendorOffersByStatus = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Marketplace.GetOffersByStatus, data)
  }

  //Vendor

  getVendorDetails = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Vendor.GetVendorDetails + vendorId)
  }

  getVendorDetailsById = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Vendor.GetVendorDetailsById + vendorId)
  }

  verifyVendor = (token: string | null) => {
    return httpClient.post(this.server_link + Endpoints.Vendor.VerifyVendor, {token})
  }

  updateVendorDescription = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Vendor.UpdateVendorDescription, data)
  }

  getJobsByStaffId = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobsByStaffId + hostId)
  }

  addJob = (data: ManualJobRequest) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJobForVendor, data)
  }
  getJobServices = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobServices + jobId)
  }

  getJobDetails = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobDetails + jobId)
  }

  addJobService = (data: JobServiceAddRequest) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJobService, data)
  }

  deleteJobService = (serviceId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Job.DeleteJobService + serviceId)
  }

  getJobNotes = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobNotes + jobId)
  }

  addJobNote = (data: JobNoteRequest) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJobNote, data)
  }

  getJobTasks = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobTasks + jobId)
  }

  getJobStaffs = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobStaffs + jobId)
  }

  addJobTasks = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJobTask, data)
  }

  updateJobTask = (jobTaskId: any, data: any) => {
    return httpClient.put(this.server_link + Endpoints.Job.UpdateJobTask + jobTaskId, data)
  }

  deleteJobTask = (jobTaskId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Job.DeleteJobTask + jobTaskId)
  }

  getJobIssues = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobIssues + jobId)
  }

  resolveIssue = (jobIssueId: number) => {
    return httpClient.put(this.server_link + Endpoints.Job.ResolveIssue + jobIssueId)
  }

  addJobIssue = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJobIssue, data)
  }

  updateJobIssue = (jobIssueId: any, data: any) => {
    return httpClient.put(this.server_link + Endpoints.Job.UpdateJobIssue + jobIssueId, data)
  }

  deleteJobIssue = (jobTaskId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Job.DeleteJobIssue + jobTaskId)
  }

  getChecklistPhotos = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetChecklistPhotos + jobId)
  }

  getJobReview = (jobId: any, serviceTypeId: any) => {
    return httpClient.get(
      this.server_link + Endpoints.Job.GetJobReview + jobId + '&serviceTypeId=' + serviceTypeId
    )
  }

  addJobReview = (data: JobReviewRequest) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJobReview, data)
  }

  //Turnovers
  getFutureTurnovers = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Turnovers.GetFutureTurnovers + hostId)
  }

  getPastTurnovers = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Turnovers.GetPastTurnovers + hostId)
  }

  //PMS
  getPMSTypes = () => {
    return httpClient.get(this.server_link + Endpoints.PMS.GetPMSTypes)
  }

  getPropertiesFromGuesty = (data: GuestyRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetPropertiesFromGuesty, data)
  }

  getPropertiesFromHostify = (data: HostawayRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetPropertiesFromHostify, data)
  }

  getPropertiesFromMyVr = (data: MyVrRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetPropertiesFromMyVr, data)
  }

  postHostPropertyList = (data: IHostPropertyInput[]) => {
    return httpClient.post(this.server_link + Endpoints.Property.PostHostProperties, data)
  }

  getHostProperties = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Property.GetProperties + '?hostId=' + hostId)
  }

  postHostProperty = (data: IHostPropertyInput) => {
    return httpClient.post(this.server_link + Endpoints.Property.PostHostProperty, data)
  }

  updateHostProperty = (hostPropertyId: number, data: IHostPropertyInput) => {
    return httpClient.put(
      this.server_link + Endpoints.Property.UpdateHostProperty + hostPropertyId,
      data
    )
  }

  getHostPropertyOtherQuestion = () => {
    return httpClient.get(this.server_link + Endpoints.Property.HostPropertyOtherQuestion)
  }

  getHostPropertyById = (propertyId: number) => {
    return httpClient.get(this.server_link + Endpoints.Property.GetHostPropertyDetail + propertyId)
  }

  getAllowedProperties = (staffId: any) => {
    return httpClient.get(this.server_link + Endpoints.Property.AllowedProperties + staffId)
  }

  getAcceptedProperties = (hostPropertyId: any) => {
    return httpClient.get(this.server_link + Endpoints.Property.AcceptedProperties + hostPropertyId)
  }

  addPropertyPermission = (data: PermissionRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.AddPropertyPermission, data)
  }

  deletePropertyPermission = (data: PermissionRequest) => {
    return httpClient.delete(
      this.server_link +
        Endpoints.Property.DeletePropertyPermission +
        data.userId +
        '/' +
        data.hostPropertyId
    )
  }
  //Vendor
  getVendorById = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Vendor.GetVendorDetails + vendorId)
  }
  postBillingInformation = (data: IBillingInformation) => {
    return httpClient.post(this.server_link + Endpoints.Vendor.PostBillingInformation, data)
  }

  forgotPassword = (data: ForgotRequest) => {
    return httpClient.post(this.server_link + Endpoints.Vendor.Forgotpassword, data)
  }
  resetPassword = (data: AcceptStaffInviteRequest) => {
    return httpClient.post(this.server_link + Endpoints.Vendor.ResetPassword, data)
  }
  //OnBoarding
  getOnBoarding = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.OnBoarding.GetOnBoardingInfo + vendorId)
  }
  //Countries
  getCountries = () => {
    return httpClient.get(this.server_link + Endpoints.Countries.GetCountries)
  }

  //Cleaning Providers
  getCleaningProviders = () => {
    return httpClient.get(this.server_link + Endpoints.CleaningProviders.GetCleaningProviders)
  }

  //Access Types
  getAccessTypes = () => {
    return httpClient.get(this.server_link + Endpoints.AccessTypes.GetAccessTypes)
  }

  //Checklists
  getHostMasterChecklists = (hostId: any) => {
    return httpClient.get(
      this.server_link + Endpoints.Checklist.GetMasterChecklists + '?hostId=' + hostId
    )
  }

  getMasterChecklists = () => {
    return httpClient.get(this.server_link + Endpoints.Checklist.GetMasterChecklists)
  }

  getHostPropertyChecklists = (hostPropertyId?: number, roleId?: any, serviceTypeId?: any) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Checklist.GetHostPropertyChecklists +
        hostPropertyId +
        (serviceTypeId ? `&serviceTypeId=${serviceTypeId}` : '') +
        (roleId ? `&roleId=${roleId}` : '')
    )
  }

  postCloneCheckLists = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Checklist.PostCloneChecklist, data)
  }

  postHostPropertyChecklist = (data: ChecklistAddRequest) => {
    return httpClient.post(this.server_link + Endpoints.Checklist.PostHostPropertyChecklist, data)
  }

  editChecklist = (data: ChecklistEditRequest, hostPropertyChecklistId?: number) => {
    return httpClient.put(
      this.server_link + Endpoints.Checklist.EditChecklist + hostPropertyChecklistId,
      data
    )
  }

  getChecklistItems = (checklistId: number) => {
    return httpClient.get(this.server_link + Endpoints.Checklist.GetChecklistItems + checklistId)
  }

  addChecklistSection = (data: ChecklistSectionAddRequest) => {
    return httpClient.post(this.server_link + Endpoints.Checklist.AddChecklistSection, data)
  }

  getChecklistSectionTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Checklist.GetChecklistSectionTypes)
  }

  setSectionOrder = (data: ChecklistSetSectionOrderRequest[], checklistId?: number) => {
    return httpClient.put(
      this.server_link + Endpoints.Checklist.SetSectionOrder + checklistId + '/sections/order',
      data
    )
  }

  setQuestionOrder = (data: ChecklistSetQuestionOrderRequest[], sectionId?: number) => {
    return httpClient.put(
      this.server_link + Endpoints.Checklist.SetSectionOrder + sectionId + '/questions/order',
      data
    )
  }

  editSection = (data: ChecklistSectionEditRequest, sectionId?: number) => {
    return httpClient.put(this.server_link + Endpoints.Checklist.EditSection + sectionId, data)
  }

  editQuestion = (data: ChecklistQuestionEditRequest, questionId?: number) => {
    return httpClient.put(this.server_link + Endpoints.Checklist.EditQuestion + questionId, data)
  }

  addQuestion = (data: any) => {
    return httpClientFormData.post(this.server_link + Endpoints.Checklist.AddQuestion, data)
  }

  editQuestionPhoto = (data: any, samplePhotoId: any) => {
    return httpClientFormData.put(
      this.server_link + Endpoints.Checklist.EditQuestionPhoto + samplePhotoId,
      data
    )
  }

  newQuestionPhoto = (data: any) => {
    return httpClientFormData.post(this.server_link + Endpoints.Checklist.NewQuestionPhoto, data)
  }

  updateChecklistMainInfo = (
    data: ChecklistMainUpdateRequest,
    hostPropertyChecklistId?: number
  ) => {
    return httpClient.put(
      this.server_link + Endpoints.Checklist.UpdateMainChecklist + hostPropertyChecklistId,
      data
    )
  }

  deleteChecklist = (checklistId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Checklist.DeleteChecklist + checklistId)
  }

  updateFutureJobsChecklistsByProperty = (data: any) => {
    return httpClient.put(
      this.server_link + Endpoints.Checklist.UpdateFutureJobsChecklistsByProperty,
      data
    )
  }

  deleteChecklistSection = (sectionId?: number) => {
    return httpClient.delete(this.server_link + Endpoints.Checklist.DeleteSection + sectionId)
  }

  deleteSectionQuestion = (questionId?: number) => {
    return httpClient.delete(this.server_link + Endpoints.Checklist.DeleteQuestion + questionId)
  }

  deleteSamplePhoto = (samplePhotoId: any) => {
    return httpClient.delete(
      this.server_link + Endpoints.Checklist.DeleteSamplePhoto + samplePhotoId
    )
  }

  //Vendor Reference
  getVendorReference = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Reference.GetReference + vendorId)
  }
  deleteVendorReference = (vendorId: number) => {
    return httpClient.delete(this.server_link + Endpoints.Reference.DeleteReference + vendorId)
  }
  updateVendorReference = (id: number, data: ReferenceUpdateRequest) => {
    return httpClient.put(this.server_link + Endpoints.Reference.UpdateReference + id, data)
  }
  createVendorReference = (data: ReferenceRequest) => {
    return httpClient.post(this.server_link + Endpoints.Reference.CreateReference, data)
  }
  //Vendor Documents
  deleteVendorDocument = (documentId: number) => {
    return httpClient.delete(
      this.server_link + Endpoints.Documents.DeleteDocumentDetail + documentId
    )
  }
  getVendorDocumentDetail = (documentId: number) => {
    return httpClient.get(this.server_link + Endpoints.Documents.GetDocuments + documentId)
  }
  getVendorDocumentsTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Documents.GetDocumentsTypes)
  }
  getVendorDocuments = (vendorId: number, documentTypeId?: any) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Documents.GetDocuments +
        '?vendorId=' +
        vendorId +
        (documentTypeId ? '&documentTypeId=' + documentTypeId : '')
    )
  }
  createVendorDocuments = (data: any) => {
    return httpClientFormData.post(this.server_link + Endpoints.Documents.GetDocuments, data)
  }

  //Staff
  getStaff = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetStaffs + '?vendorId=' + vendorId)
  }

  createStaff = (data: StaffAddRequest) => {
    return httpClient.post(this.server_link + Endpoints.Staff.CreateStaff, data)
  }
  getPendingStaff = (vendorId: number, accountStatusId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Staff.GetPendingStaffs +
        'vendorId=' +
        vendorId +
        '&accountStatusId=' +
        accountStatusId
    )
  }
  updateStaffStatus = (userId: number, data: any) => {
    return httpClient.put(this.server_link + Endpoints.Staff.UpdatePendingStaffs + userId, data)
  }
  getAvailableStaff = (
    vendorId: number,
    roleId: number,
    hostPropertyId: number,
    cleaningPeriodStart: string
  ) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Staff.GetAvailableStaffs +
        'vendorId=' +
        vendorId +
        '&roleId=' +
        roleId +
        '&hostPropertyId=' +
        hostPropertyId +
        '&scheduledStart=' +
        cleaningPeriodStart
    )
  }
  getStaffStatusTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Staff.StaffStatusTypes)
  }
  updateVendorStaffStatus = (data: any, userId: any) => {
    return httpClient.put(
      this.server_link + Endpoints.Staff.UpdateVendorStaffStatus + '/' + userId,
      data
    )
  }
  acceptInvite = (data: AcceptStaffInviteRequest) => {
    return httpClient.post(this.server_link + Endpoints.Staff.AcceptInvite, data)
  }
  getStaffCityService = (userId: number, vendorId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Staff.StaffServiceCities +
        '?userId=' +
        userId +
        '&vendorId=' +
        vendorId
    )
  }
  postStaffCityService = (data: StaffCityService) => {
    return httpClient.post(this.server_link + Endpoints.Staff.StaffServiceCities, data)
  }
  deleteStaffCityService = (data: StaffCityService) => {
    return httpClient.delete(this.server_link + Endpoints.Staff.StaffServiceCities, {data})
  }
  postReMailStaff = (data: {email: string; vendorUserId: number}) => {
    return httpClient.post(this.server_link + Endpoints.Staff.ReMailStaff, data)
  }

  //Vendor-user-document
  getVendorUserDocumentTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetUserDocumentTypes)
  }
  getVendorUserDocuments = (userId: number, vendorId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Staff.GetUserDocuments +
        'userId=' +
        userId +
        '&vendorId=' +
        vendorId
    )
  }
  createVendorUserDocuments = (data: FormData) => {
    return httpClient.post(this.server_link + Endpoints.Staff.CreateUserDocument, data)
  }
  deleteVendorUserDocuments = (vendorUserDocumentId: number) => {
    return httpClient.delete(
      this.server_link + Endpoints.Staff.DeleteUserDocument + vendorUserDocumentId
    )
  }
  // Vendor-city-service
  getVendorCityService = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.CityService.GetVendorCityService + vendorId)
  }
  createVendorCityService = (data: CityService) => {
    return httpClient.post(this.server_link + Endpoints.CityService.CreateVendorCityService, data)
  }
  deleteVendorCityService = (zipId: number) => {
    return httpClient.delete(
      this.server_link + Endpoints.CityService.DeleteVendorCityService + zipId
    )
  }
  //Roles
  getStaffRoles = () => {
    return httpClient.get(this.server_link + Endpoints.Roles.GetStaffRoles)
  }
  //Violation

  getViolation = (vendorId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Violation.GetViolation + '?vendorId=' + vendorId
    )
  }

  //Service
  getServiceTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Service.GetServiceTypes)
  }

  //Answer Types
  getChecklistAnswerTypes = () => {
    return httpClient.get(this.server_link + Endpoints.ChecklistAnswerTypes.GetChecklistAnswerTypes)
  }

  //Languages
  getLanguages = () => {
    return httpClient.get(this.server_link + Endpoints.Languages.GetLanguages)
  }

  //Address
  getAddressesByUserId = (userId: any) => {
    return httpClient.get(this.server_link + Endpoints.Address.GetAddressByUserId + userId)
  }

  createAddress = (userId: any, data: AddressRequest | IStaffAddress) => {
    return httpClient.post(
      this.server_link + Endpoints.Address.CreateAddress + userId + '/address',
      data
    )
  }

  updateAddress = (userId: any, addressId: any, data: AddressRequest | IStaffAddress) => {
    return httpClient.put(
      this.server_link + Endpoints.Address.UpdateAddress + userId + '/address/' + addressId,
      data
    )
  }

  deleteAddress = (addressId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Address.DeleteAddress + addressId)
  }

  getAddressTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Address.GetAddressTypes)
  }

  //Offered Services
  deleteStaffsOfferedService = (offeredId: number) => {
    return httpClient.delete(this.server_link + Endpoints.OfferedServices.DeleteOffered + offeredId)
  }
  changeStaffsOfferedService = (staffId: number, data: IOfferedPut) => {
    return httpClient.put(
      this.server_link + Endpoints.OfferedServices.ChangeOffered + staffId,
      data
    )
  }
  createOfferedService = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.OfferedServices.CreateOfferedService, data)
  }

  getOfferedServices = (userId: any) => {
    return httpClient.get(this.server_link + Endpoints.OfferedServices.GetOfferedServices + userId)
  }
  getOfferedServicesByHostId = (hostId: any) => {
    return httpClient.get(
      this.server_link + Endpoints.OfferedServices.GetOfferedServicesByHostId + hostId
    )
  }

  getHostPropertyServiceOffers = (hostPropertyId: number | string | undefined) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Property.GetHostPropertyServiceOffers +
        '?hostPropertyId=' +
        hostPropertyId
    )
  }

  getHostPropertyServiceOffersForVendor = (hostPropertyId: number | string | undefined) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Property.GetHostPropertyServiceOffersForVendor +
        '?hostPropertyId=' +
        hostPropertyId
    )
  }

  getHostPropertyServiceRatesForVendor = (hostPropertyId: number | string | undefined) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.GetHostPropertyServiceRatesForVendor + hostPropertyId
    )
  }

  getSuitableVendorByJobId = (jobId: number | null) => {
    return httpClient.get(this.server_link + Endpoints.Vendors.GetSuitableVendorsByJobId + jobId)
  }

  getJobsDailyByStaffId = (userId: number | undefined) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobsDailyByStaffId + userId)
  }

  getAvailableVendors = (hostPropertyId: number | string | undefined) => {
    return httpClient.get(this.server_link + Endpoints.Vendors.GetAvailableVendors + hostPropertyId)
  }

  getOfferedStaffs = (hostId: any, hostPropertyId: any, serviceTypeId: any) => {
    return httpClient.get(
      this.server_link +
        Endpoints.OfferedServices.GetOfferedStaffs +
        serviceTypeId +
        '&hostId=' +
        hostId +
        '&hostPropertyId=' +
        hostPropertyId
    )
  }
  getVendorsOfferedServices = (vendorId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.OfferedServices.GetOfferedServicesByVendorId + vendorId
    )
  }

  //Vendor Offered Services

  getVendorOfferedServices = (vendorId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.VendorOfferedServices.CreateVendorOfferedService +
        '?vendorId=' +
        vendorId
    )
  }

  getVendorServices = (vendorId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.VendorOfferedServices.GetVendorService + vendorId
    )
  }

  addVendorService = (data: IVendorServiceRequest) => {
    return httpClient.post(
      this.server_link + Endpoints.VendorOfferedServices.AddVendorService,
      data
    )
  }

  postVendorServices = (data: IVendorServiceRequest) => {
    return httpClient.post(
      this.server_link + Endpoints.VendorOfferedServices.CreateVendorOfferedService,
      data
    )
  }

  editVendorServices = (id: any, data: IServicePrice) => {
    return httpClient.put(
      this.server_link + Endpoints.VendorOfferedServices.CreateVendorOfferedService + '/' + id,
      data
    )
  }

  deleteVendorService = (id: number) => {
    return httpClient.delete(
      this.server_link + Endpoints.VendorOfferedServices.DeleteVendorOffered + '/' + id
    )
  }

  //Off Days
  getStaffOffDays = (staffId: any) => {
    return httpClient.get(this.server_link + Endpoints.OffDays.GetStaffOffDays + staffId)
  }
  postStaffOffDays = (data: IPostOffDays) => {
    return httpClient.post(this.server_link + Endpoints.OffDays.PostStaffOffDays, data)
  }
  deleteStaffOffDays = (offDaysId: number) => {
    return httpClient.delete(
      this.server_link + Endpoints.OffDays.DeleteStaffOffDays + '/' + offDaysId
    )
  }
  //Off Time
  getStaffOffTime = (staffId: any, roleId?: any) => {
    return httpClient.get(
      this.server_link + Endpoints.OffDays.GetStaffOffTime + staffId + '&roleId=' + roleId
    )
  }
  postStaffOffTime = (data: any, roleId?: any) => {
    return httpClient.post(this.server_link + Endpoints.OffDays.PostStaffOffTime, data)
  }
  deleteStaffOffTime = (offTimeId: any, roleId?: any) => {
    return httpClient.delete(this.server_link + Endpoints.OffDays.DeleteStaffOffTime + offTimeId)
  }

  //Rate Types
  getRateTypes = () => {
    return httpClient.get(this.server_link + Endpoints.JobRatingTypes.GetJobRatingTypes)
  }

  getAllRateTypes = () => {
    return httpClient.get(this.server_link + Endpoints.RateTypes.GetRateTypes)
  }

  //Billings
  getBillingsByVendorId = (vendorId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Billings.GetBillings + 'vendorId=' + vendorId
    )
  }
  verifyBilling = () => {
    return httpClient.post(this.server_link + Endpoints.Billings.VerifyBilling, {
      isVerifiedStripeAccount: true,
    })
  }
  disabledBilling = () => {
    return httpClient.delete(this.server_link + Endpoints.Billings.DisableStripe)
  }
  getStripeAccountLink = () => {
    return httpClient.get(this.server_link + Endpoints.Billings.AccountLinks)
  }
  getCustomerSources = () => {
    return httpClient.get(this.server_link + Endpoints.Billings.GetPaymentSource)
  }
  getPaymentAccounts = () => {
    return httpClient.get(this.server_link + Endpoints.Billings.GetPaymentAccounts)
  }
  createPaymentAccounts = () => {
    return httpClient.post(this.server_link + Endpoints.Billings.CreatePaymentAccounts)
  }
  postCustomerSource = (data: {source: string}) => {
    return httpClient.post(this.server_link + Endpoints.Billings.PostCustomerSource, data)
  }
  putDefaultCustomerSource = (data: {source: string}) => {
    return httpClient.put(this.server_link + Endpoints.Billings.PutDefaultCustomerSource, data)
  }
  deleteCustomerSource = (sourceId: string) => {
    return httpClient.delete(this.server_link + Endpoints.Billings.DeleteCustomerSource + sourceId)
  }

  // Payment
  getPaymentByVendorId = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Payment.GetPayment + 'vendorId=' + vendorId)
  }
  getUnpaidPaymentByVendorId = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Payment.GetUnpaidPayment + vendorId)
  }

  //SubscriptionPacks
  getSubscriptionPacks = () => {
    return httpClient.get(this.server_link + Endpoints.Subscription.GetPacks)
  }
  subscribeToPack = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Subscription.SubscribeToPack, data)
  }
  getVendorSubscriptions = (vendorId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Subscription.GetVendorSubscriptions + vendorId
    )
  }
  getVendorSuspendedInvoice = (vendorId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Subscription.GetVendorSuspendedInvoice + vendorId
    )
  }
  getVendorSubscriptionInvoiceDetail = (invoiceNum: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Subscription.GetVendorSubscriptionInvoiceDetail + invoiceNum
    )
  }
  chargeSubscriptionInvoice = (invoiceNum: number) => {
    return httpClient.post(
      this.server_link + Endpoints.Subscription.ChargeSubscriptionInvoice + invoiceNum
    )
  }
  cancelSubscription = (id: number) => {
    return httpClient.get(this.server_link + Endpoints.Subscription.CancelSubscription + id)
  }
  getSubscriptionStaffFee = () => {
    return httpClient.get(this.server_link + Endpoints.Subscription.GetVendorSubscriptionStaffFee)
  }

  //Search
  getSearch = (searchText: string | null) => {
    return httpClient.get(this.server_link + Endpoints.Search.GetSearch + searchText)
  }

  //Notification
  getNotifications = () => {
    return httpClient.get(this.server_link + Endpoints.Notification.GetNotifications)
  }

  readNotification = (data: number[]) => {
    return httpClient.put(this.server_link + Endpoints.Notification.ReadNotification, {
      notificationIds: [...data],
    })
  }

  //Service Rate Quote Request
  getServiceRateQuoteRequests = (vendorId: number, offerStatusId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.ServiceRateQuoteRequests.GetServiceRateQuoteRequests +
        '?vendorId=' +
        vendorId +
        '&hostPropertyServiceRateOfferTypeId=' +
        offerStatusId
    )
  }

  getVendorServiceRates = (vendorId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.ServiceRateQuoteRequests.GetVendorRates + vendorId
    )
  }

  sendServiceRateQuoteRequestOffer = (data: any) => {
    return httpClient.put(
      this.server_link + Endpoints.ServiceRateQuoteRequests.SendServiceRateQuoteRequestOffer,
      data
    )
  }

  addPropertyServiceRate = (data: any) => {
    return httpClient.post(
      this.server_link + Endpoints.ServiceRateQuoteRequests.AddServiceRate,
      data
    )
  }

  //Property&Host
  postResetPropertyJobs = (propertyId: number) => {
    return httpClient.get(this.server_link + Endpoints.Property.PostResetJobs + propertyId)
  }

  postResetHostPropertyJobs = (propertyId: number) => {
    return httpClient.get(this.server_link + Endpoints.Property.PostResetHostJobs + propertyId)
  }

  createHostProperty = (data: IHostPropertyVendorInput) => {
    return httpClient.post(this.server_link + Endpoints.Property.CreateHostProperty, data)
  }

  getPropertyRelatedJobs = (hostPropertyId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Job.AddJob +
        '?hostPropertyId=' +
        hostPropertyId +
        '&jobStatusTypeId=1' +
        '&jobStatusTypeId=2' +
        '&jobStatusTypeId=3' +
        '&jobStatusTypeId=4'
    )
  }

  getPropertiesByStatusId = (
    statusId: number | null,
    cleaningProviderId: any = null,
    isMarketplace: any = null
  ) => {
    if (cleaningProviderId != null) {
      var query = statusId + '&cleaningProviderId=' + cleaningProviderId
      if (isMarketplace != null) {
        query =
          statusId + '&cleaningProviderId=' + cleaningProviderId + '&isMarketplace=' + isMarketplace
      }
      return httpClient.get(this.server_link + Endpoints.Property.GetPropertiesByStatusId + query)
    } else {
      return httpClient.get(
        this.server_link + Endpoints.Property.GetPropertiesByStatusId + statusId
      )
    }
  }

  getAllPropertiesByStatusId = (
    statusId: number | null,
    cleaningProviderId: any = null,
    isMarketplace: any = null
  ) => {
    if (cleaningProviderId != null) {
      var query = statusId + '&cleaningProviderId=' + cleaningProviderId
      if (isMarketplace != null) {
        query =
          statusId + '&cleaningProviderId=' + cleaningProviderId + '&isMarketplace=' + isMarketplace
      }
      return httpClient.get(
        this.server_link + Endpoints.Property.GetAllPropertiesByStatusId + query
      )
    } else {
      return httpClient.get(
        this.server_link + Endpoints.Property.GetAllPropertiesByStatusId + statusId
      )
    }
  }

  getVendorAvailableProperties = (vendorId: number | null) => {
    return httpClient.get(this.server_link + Endpoints.Property.GetVendorRangeProperties + vendorId)
  }

  getProperties = () => {
    return httpClient.get(this.server_link + Endpoints.Property.GetProperties)
  }

  getAllProperties = () => {
    return httpClient.get(this.server_link + Endpoints.Property.GetAllProperties)
  }

  getPropertiesByHost = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Property.GetPropertiesByHost + hostId)
  }

  editPropertyStatus = (propertyId: any, statusId: number) => {
    return httpClient.put(
      this.server_link + Endpoints.Property.EditPropertyStatus + propertyId + '/edit-status',
      {hostPropertyId: parseInt(propertyId), hostPropertyStatusTypeId: statusId}
    )
  }

  getPropertyIsAutoEnabled = (propertyId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.GetPropertyIsAutoEnabled + propertyId
    )
  }

  postRefreshCalendar = (propertyId: number) => {
    return httpClient.get(this.server_link + Endpoints.Property.PostRefreshCalendar + propertyId)
  }

  getAllHosts = () => {
    return httpClient.get(this.server_link + Endpoints.Host.GetHosts)
  }

  addHost = (data: HostRegisterRequest) => {
    return httpClient.post(this.server_link + Endpoints.Host.AddHost, data)
  }

  getHostById = (hostId: any) => {
    return httpClient.get(this.server_link + Endpoints.Host.GetHostById + hostId)
  }

  getHostUsers = (hostId: any) => {
    return httpClient.get(this.server_link + Endpoints.Host.GetHostUsers + hostId)
  }

  getHostStatusTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Host.GetHostStatusTypes)
  }

  updateHost = (hostId?: number, data?: IHostEdit) => {
    return httpClient.put(this.server_link + Endpoints.Host.UpdateHost + hostId, data)
  }

  getJobsByHost = (hostId?: string | number) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobs + '?hostId=' + hostId)
  }

  getJobsByPropertyId = (
    hostPropertyId: number,
    cleaningPeriodStart: any,
    cleaningPeriodEnd: any
  ) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Job.GetJobsByPropertyId +
        hostPropertyId +
        '&cleaningPeriodStart=' +
        cleaningPeriodStart +
        '&cleaningPeriodEnd=' +
        cleaningPeriodEnd
    )
  }

  getPermissionVendors = (hostPropertyId: number) => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetPermissionsStaffs + hostPropertyId)
  }

  getExcludedStaffByPropertyId = (propertyId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.GetExcludedStaff + propertyId + '/users'
    )
  }

  deleteExcludeStaff = (excludeId: number) => {
    return httpClient.delete(this.server_link + Endpoints.Property.DeleteExcludedStaff + excludeId)
  }

  getStaffByVendorId = (vendorId: number | undefined) => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetStaffsByVendorId + vendorId)
  }

  getStaffsForAddJob = (
    vendorId?: number | string,
    roleId?: number | string,
    propertyId?: number | string,
    cleaningPeriodStart?: string
  ) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Staff.GetStaffsForAddJob +
        vendorId +
        '&roleId=' +
        roleId +
        '&hostPropertyId=' +
        propertyId +
        '&scheduledStart=' +
        cleaningPeriodStart
    )
  }

  getHostSearch = (searchText: string | null) => {
    return httpClient.get(this.server_link + Endpoints.Search.GetHostSearch + searchText)
  }

  validateICal = (link: string) => {
    return httpClient.post(this.server_link + Endpoints.ICalValidation.ValidateICall, {link})
  }

  getServiceGroupsWithServiceTypes = () => {
    return httpClient.get(
      this.server_link + Endpoints.ServiceGroup.GetServiceGroupsWithServiceTypes
    )
  }
}

export default new ApiCalls()
